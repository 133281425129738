import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  CodeSnippet,
  HasErrorPartial,
  HelpTextPartial,
  InputDisplayTypePartial,
  Link,
  List,
  PageNavigation,
  Paragraph,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
  TestIdPartial,
  AccessibilityLabelPartial,
  SpacePartial,
  IsReadOnlyPartial,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const checkboxSnippet = require('raw-loader!../../../../data/snippets/rn-checkbox.example');
const checkboxGroupSnippet = require('raw-loader!../../../../data/snippets/rn-checkbox-group.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <PageNavigation links={['Single Checkbox', 'Checkbox Group']} />
      <Section title="Single Checkbox">
        <CodeSnippet
          disableCodeEditing
          code={checkboxSnippet}
          platform="react-native"
          gitHubLink="forms/checkbox"
        />

        <Section title="Props">
          <PropList header="Visual">
            <InputDisplayTypePartial />
            <PropListItem name="isChecked" types={['boolean']}>
              <Text>Determines whether the checkbox is checked.</Text>
            </PropListItem>

            <PropListItem name="isDisabled" types={['boolean']}>
              <Text>Determines whether the checkbox is disabled.</Text>
            </PropListItem>

            <PropListItem name="label" types={['string']} isRequired>
              <Text>The label that appears to the right of the checkbox.</Text>
            </PropListItem>

            <PropListItem name="size" types={['string']}>
              <Text>Determines the checkbox's size.</Text>
              <List type="unordered">
                <li>
                  <code>small</code>
                </li>
                <li>
                  <code>medium</code> (default)
                </li>
                <li>
                  <code>large</code>
                </li>
              </List>
            </PropListItem>

            <HasErrorPartial componentName="checkbox" />
          </PropList>

          <PropList header="Functional">
            <PropListItem name="value" types={['string']} isRequired>
              <Text>The value bound to the input.</Text>
            </PropListItem>
          </PropList>

          <PropList header="Events">
            <PropListItem name="onPress" types={['() => void']}>
              <Text>Responds to the checkbox press event.</Text>
            </PropListItem>
          </PropList>

          <PropList header="Miscellaneous">
            <AccessibilityLabelPartial componentName="checkbox" />

            <TestIdPartial componentName="checkbox" />

            <PropListItem name="...props" types={[]}>
              <Text>
                You can pass in any of the other props from a{' '}
                <Link href="https://reactnative.dev/docs/touchableopacity">
                  touchable opacity
                </Link>{' '}
                component.
              </Text>
            </PropListItem>
          </PropList>
        </Section>

        <Section title="Notes">
          <List type="unordered">
            <li>
              Do not pass the <code>isChecked</code> attribute if you're using
              this component within a CheckboxGroup. Instead, use that
              component's <code>valuesChecked</code> prop.
            </li>
          </List>
        </Section>

        <AccessibilityAuditComponentResultsSection
          platform="React Native"
          componentName="Forms / Checkbox"
        />
      </Section>
      <Section title="Checkbox Group">
        <Paragraph>
          The Checkbox Group is the parent element of related checkboxes from
          which a user is expected to choose one of the options.
        </Paragraph>
        <CodeSnippet
          disableCodeEditing
          code={checkboxGroupSnippet}
          platform="react-native"
          gitHubLink="forms/checkbox-group"
        />
        <Section title="Props">
          <PropList header="Visual">
            <PropListItem name="children" types={['ReactNode']}>
              <Text>The group of checkboxes.</Text>
            </PropListItem>

            <InputDisplayTypePartial />

            <PropListItem name="header" types={['string']}>
              <Text>The text that appears above the group of checkboxes.</Text>
            </PropListItem>

            <HelpTextPartial componentName="checkbox group" />

            <PropListItem name="size" types={['string']}>
              <Text>Determines the checkbox's size.</Text>
              <List type="unordered">
                <li>
                  <code>small</code>
                </li>
                <li>
                  <code>medium</code> (default)
                </li>
                <li>
                  <code>large</code>
                </li>
              </List>
            </PropListItem>

            <PropListItem name="isDisabled" types={['boolean']}>
              <Text>
                Determines whether the entire checkbox group is disabled.
              </Text>
            </PropListItem>

            <IsReadOnlyPartial componentName="checkbox" />

            <PropListItem name="isRequired" types={['boolean']}>
              <Text>
                Determines whether the required text appears next to the header.
              </Text>
            </PropListItem>

            <SpacePartial />

            <PropListItem name="valuesChecked" types={['string[]']}>
              <Text>The values checked in the group.</Text>
            </PropListItem>
          </PropList>

          <HasErrorPartial componentName="checkbox group" />

          <PropList header="Events">
            <PropListItem name="onPress" types={['(value: string) => void']}>
              <Text>
                Responds to the checkbox being checked or unchecked. The value
                of the clicked checkbox is passed.
              </Text>
            </PropListItem>
          </PropList>

          <PropList header="Miscellaneous">
            <AccessibilityLabelPartial componentName="checkbox group" />

            <TestIdPartial componentName="checkbox group" />

            <PropListItem name="...props" types={[]}>
              <Text>
                You can pass in any of the other props from a{' '}
                <Link href="https://reactnative.dev/docs/touchableopacity">
                  touchable opacity
                </Link>{' '}
                component.
              </Text>
            </PropListItem>
          </PropList>
        </Section>
        <Section title="Notes">
          <List type="unordered">
            {/* eslint-disable max-len */}
            <li>
              Since this is a stateless component, you'll have to keep track of
              the selected checkboxes via your app's state. Use the array{' '}
              <Link href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/concat">
                concat
              </Link>{' '}
              and{' '}
              <Link href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/filter">
                filter
              </Link>{' '}
              functions to easily add or remove values.
            </li>
            {/* eslint-enable max-len */}
          </List>
        </Section>
        <AccessibilityAuditComponentResultsSection
          platform="React Native"
          componentName="Forms / Checkbox Group"
        />{' '}
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
