import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  FormSizePartial,
  HasErrorPartial,
  HelpTextPartial,
  InputDisplayTypePartial,
  IsDisabledPartial,
  IsReadOnlyPartial,
  IsRequiredPartial,
  Link,
  List,
  OrientationPartial,
  PageNavigation,
  Paragraph,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Checkbox = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Checkbox;
    return <Component {...props} />;
  },
});

const CheckboxGroup = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.CheckboxGroup;
    return <Component {...props} />;
  },
});

const checkboxSnippet = require('raw-loader!../../../../data/snippets/checkbox.example');
const checkboxGroupSnippet = require('raw-loader!../../../../data/snippets/checkbox-group.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Checkbox"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/forms-checkboxes--docs"
      />
      <PageNavigation links={['Single Checkbox', 'Checkbox Group']} />
      <Section title="Single Checkbox">
        <CodeSnippet
          scope={{ React: React, Checkbox: Checkbox }}
          code={checkboxSnippet}
          platform="react"
          gitHubLink="forms/checkbox"
        />

        <Section title="Props">
          <PropList header="Visual">
            <ClassnamePartial componentName="checkbox" />

            <InputDisplayTypePartial />

            <PropListItem name="isChecked" types={['boolean']}>
              <Text>Determines whether the checkbox is checked.</Text>
            </PropListItem>

            <IsDisabledPartial componentName="checkbox" />

            <IsReadOnlyPartial componentName="checkbox" />

            <PropListItem name="label" types={['string']}>
              <Text>The label that appears to the right of the checkbox.</Text>
            </PropListItem>
          </PropList>

          <PropList header="Miscellaneous">
            <QaIdPartial componentName="checkbox" passedDown={['label']} />

            <PropListItem name="...props" types={[]}>
              <Text>
                You can pass in any of the other props from an HTML{' '}
                <Link href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox">
                  checkbox
                </Link>{' '}
                element.
              </Text>
            </PropListItem>
          </PropList>
        </Section>

        <Section title="Notes">
          <List type="unordered">
            <li>
              Since the label wraps the input, there is no need for a{' '}
              <code>name</code> attribute.
            </li>
            <li>
              Do not pass the <code>checked</code> attribute if you're using
              this component within a <code>CheckboxGroup</code>. Instead, use
              that component's <code>valuesChecked</code> prop.
            </li>
          </List>
        </Section>

        <AccessibilityAuditComponentResultsSection
          platform="React"
          componentName="Forms / Checkbox"
        />
      </Section>

      <Section title="Checkbox Group">
        <Paragraph>
          The Checkbox Group is the parent element of related checkboxes from
          which a user is expected to choose one of the options.
        </Paragraph>

        <CodeSnippet
          scope={{
            React: React,
            Checkbox: Checkbox,
            CheckboxGroup: CheckboxGroup,
          }}
          code={checkboxGroupSnippet}
          platform="react"
          gitHubLink="forms/checkbox-group"
          disableCodeEditing
          id="checkbox-group-snippet"
        />

        <Section title="Props">
          <PropList header="Visual">
            <PropListItem name="children" types={['ReactNode']} isRequired>
              <Text>The checkbox components to show in the group.</Text>
            </PropListItem>

            <ClassnamePartial componentName="checkbox group" />

            <InputDisplayTypePartial />

            <EnvironmentPartial />

            <FormSizePartial componentName="checkbox group" />

            <HasErrorPartial componentName="checkbox group" />

            <PropListItem name="header" types={['string']}>
              <Text>The text that appears above the group of checkboxes.</Text>
            </PropListItem>

            <HelpTextPartial componentName="checkbox group" />

            <IsDisabledPartial componentName="checkbox group" />

            <IsReadOnlyPartial componentName="checkbox group" />

            <IsRequiredPartial componentName="checkbox group" />

            <OrientationPartial
              componentName="checkbox group"
              defaultOrientation="vertical"
            />

            <PropListItem name="valuesChecked" types={['string[]']}>
              <Text>The values checked in the group.</Text>
            </PropListItem>
          </PropList>

          <PropList header="Events">
            <PropListItem
              name="onChange"
              types={['(value: string | number) => void']}>
              <Text>
                Responds to the checkbox being checked or unchecked. The value
                of the checkbox is passed.
              </Text>
            </PropListItem>
          </PropList>

          <PropList header="Miscellaneous">
            <QaIdPartial
              componentName="checkbox group"
              passedDown={['header', 'help-text']}
            />
          </PropList>
        </Section>

        <Section title="Notes">
          {/* eslint-disable max-len */}
          <List type="unordered">
            <li>
              Since this is a stateless component, you'll have to keep track of
              the selected checkboxes via your app's state. Use the array{' '}
              <Link href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/concat">
                concat
              </Link>{' '}
              and{' '}
              <Link href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/filter">
                filter
              </Link>{' '}
              functions to easily add or remove values.
            </li>
          </List>
          {/* eslint-enable max-len */}
        </Section>

        <AccessibilityAuditComponentResultsSection
          platform="React"
          componentName="Forms / Checkbox Group"
        />
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
